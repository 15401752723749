import { stripHtml } from '@src/app/utils';
import RestrictedContent from '@src/reactComponents/secureAccess/restrictedContent/restrictedContent';
import React from 'react';
import {
  canAttachmentBeRenderedInline,
  findForbiddenItem,
  getItemsToShow,
  isItemForbidden,
} from '@src/helpers/accessHelper';
import { getAttachmentType, getNameAndExtension } from '@src/app/contentUtils';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';

const ContentBlockAttachments = ({ item }) => {
  const dispatch = useDispatch();

  let accessibleAttachments = [];
  let forbiddenAttachment;
  let isAttachmentsGroupAccessible = true;

  if (item.children) {
    accessibleAttachments = getItemsToShow(item.children);
    forbiddenAttachment = findForbiddenItem(item.children);
    isAttachmentsGroupAccessible = !isItemForbidden(item);
  }

  return (
    <>
      {!isAttachmentsGroupAccessible ||
        (forbiddenAttachment && (
          <ul className="kov-pro-references kov-pro-references--not-logged-in">
            <RestrictedContent status="isAttachmentsGroupAccessible ? item.securityStatus : forbiddenAttachment.securityStatus" />
          </ul>
        ))}
      <ul className="kov-pro-references">
        {accessibleAttachments
          .filter((attachment) => attachment.attachment)
          .map((attachment, index) =>
            canAttachmentBeRenderedInline(attachment) ? (
              <li key={index} className="download">
                <a
                  className="js-is-download" // Used to avoid double tracking of the download
                  target="_blank"
                  rel="noreferrer"
                  download={attachment.title || attachment.description}
                  href={attachment.attachment.href}
                  onClick={() =>
                    dispatch(
                      trackPiwikEvent(
                        'download_file',
                        getNameAndExtension(attachment.attachment.name)
                      )
                    )
                  }
                >
                  {stripHtml(attachment.title || attachment.description)}
                  <span>
                    {attachment.attachment.size} {getAttachmentType(attachment.attachment)}
                  </span>
                </a>
              </li>
            ) : null
          )}
      </ul>
    </>
  );
};

ContentBlockAttachments.propTypes = {
  item: PropTypes.object,
};

export default ContentBlockAttachments;

import * as utils from '@src/app/utils';
import { getUserAccess, getUserKey } from '@src/reduxStore/user/userSelectors';
import { IMPORTANCELEVELS, SECURITYLEVELS } from '../components/constants';

/* Prevent circular dependencies error, we should not import store here 
https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files */
let store;
export const injectAccessStore = (_store) => {
  store = _store;
};

export function getAccessRights(item) {
  return item.type === 'UNSTRUCTURED_DOCUMENT' ? item.accessRights : item.descendantsAccessRights;
}

export function calcSecurityLevel(item) {
  const accessRights = getAccessRights(item);
  if (accessRights && accessRights.length) {
    switch (accessRights[0]) {
      case SECURITYLEVELS.SENSITIVE:
        return SECURITYLEVELS.SENSITIVE;
      case SECURITYLEVELS.VERYSENSITIVE:
        return SECURITYLEVELS.VERYSENSITIVE;
      case SECURITYLEVELS.BESTUURDERS:
        return SECURITYLEVELS.BESTUURDERS;
      default:
        return SECURITYLEVELS.PUBLIC;
    }
  }
  return SECURITYLEVELS.PUBLIC;
}

/**
 * Checks if the logged user has permissions to see an item
 * @param {*} item
 * @returns
 */
export function hasPermissions(item) {
  const userAccess = getUserAccess(store.getState());
  const nodeSecurityLevel = calcSecurityLevel(item);
  return userAccess.includes(nodeSecurityLevel);
}

export function getSecurityStatusFromSecurityLevel(securityLevel) {
  let securityStatus = 'permitted';

  if (securityLevel === SECURITYLEVELS.SENSITIVE) {
    securityStatus = 'afgeschermd2';
  } else if (securityLevel === SECURITYLEVELS.VERYSENSITIVE) {
    securityStatus = 'afgeschermd3';
  } else if (securityLevel === SECURITYLEVELS.BESTUURDERS) {
    securityStatus = 'afgeschermd4';
  }

  return securityStatus;
}

export function getSecurityStatus(item) {
  let securityStatus = 'permitted';
  const securityLevel = calcSecurityLevel(item);

  if (securityLevel !== SECURITYLEVELS.PUBLIC) {
    const userKey = getUserKey(store.getState());

    if (userKey === undefined) {
      securityStatus = 'afgeschermd1';
    } else if (!hasPermissions(item)) {
      securityStatus = getSecurityStatusFromSecurityLevel(securityLevel);
    }
  }

  return securityStatus;
}

/** Infers the possible access rights for a forbidden item from its permitted parent
 * This relies on the fact that children items of a node have the same or
 * more limited access rights as their parents. When a child of a permitted node is forbidden,
 * we can say that its access rights are at least a higher degree more restricted than its
 * parent access rights (PUBLIC < SENSITIVE < VERY SENSITIVE < BESTUURDERS);
 * otherwise the child node will also be accessible.
 * @param {*} parent node
 * @returns forbidden item's access rights
 */

export function inferForbiddenItemSecurityFromParent(parent) {
  const accessRights = getAccessRights(parent);
  if (accessRights && accessRights.length) {
    switch (accessRights[0]) {
      case SECURITYLEVELS.PUBLIC:
        return SECURITYLEVELS.SENSITIVE;
      case SECURITYLEVELS.SENSITIVE:
        return SECURITYLEVELS.VERYSENSITIVE;
      default:
        return SECURITYLEVELS.BESTUURDERS;
    }
  }

  return SECURITYLEVELS.BESTUURDERS;
}

/**
 * Gets the message to show in secure access placeholder dependings on the user
 * and the content securityStatus
 * @param {*} securityStatus
 * @returns message content for secure access placeholder
 */
export function getSecureAccessPlaceholderContent(userKey, securityStatus) {
  let link;
  let callToAction;

  if (userKey) {
    callToAction = 'Meer info';
    switch (securityStatus) {
      case 'afgeschermd2':
        link = '/geen-toegang';
        break;
      case 'afgeschermd3':
        link = '/geen-toegang-strikt';
        break;
      default:
        // case 'afgeschermd4':
        link = '/Informatie-voor-besturen';
        break;
    }
  }

  return {
    text: 'Je ziet niet alle documenten',
    link: link || '',
    callToAction: callToAction || 'Meld aan',
  };
}

/**
 * Determines whether an item is visible or not according the user's view option
 * @param {*} item
 * @returns whether an item is visible or not
 */
export function isItemVisible(item) {
  return item.visible;
}

/**
 * Filters out the visible items from a list according to the user's view option
 * @param {*} items
 * @returns list of visible items
 */
export function getVisibleItems(items) {
  return items.filter((item) => isItemVisible(item));
}

/**
 * Determines whether an item is forbidden or not according to user's acesss rights
 * @param {*} item
 * @returns whether an item is forbidden or not
 */
export function isItemForbidden(item) {
  if (item.securityStatus == null) return false;
  return item.securityStatus !== 'permitted';
}

/**
 * Gets the forbidden items from a list according to the user's access rights
 * @param {*} items
 * @returns list of forbidden items
 */
export function findForbiddenItem(items) {
  return items.find((item) => isItemForbidden(item));
}

/**
 * Gets the items from a list that the user can read according to the user's access rights
 * and view option
 * @param {*} items
 * @returns list of accesible items
 */
export function getItemsToShow(items) {
  return items.filter((item) => {
    const parentVisible = item.parent
      ? isItemVisible(item.parent) && !isItemForbidden(item.parent)
      : true;

    return isItemVisible(item) && !isItemForbidden(item) && parentVisible;
  });
}

/**
 * Gets the items from a list that the user can read according to whether the item is permitted
 * @param {*} items
 * @returns list of permitted items
 */
export function getPermittedItems(items) {
  return items.filter((item) => !isItemForbidden(item));
}

/**
 * Verifies whether an attachment can be rendered inline or not
 * An attachment can't be rendered inline if it's in an attachment group with importance of HIGH
 * @param {*} attachment
 * @returns whether an attachments group is forbidden or not
 */
export function canAttachmentBeRenderedInline(attachment) {
  if (attachment.parent == null) return true;
  if (attachment.parent.type !== 'ATTACHMENTS_GROUP') return true;
  return ![IMPORTANCELEVELS.HIGH, IMPORTANCELEVELS.VERY_HIGH].includes(
    attachment.parent.importance
  );
}

/**
 * Finds a forbidden item in a list of attachments groups according to user's access rights
 * and view option. An attachments group can contain attachments and global docs.
 * Here all those elements are checked: Attachments groups, attachments and global docs.
 * From all of them appearing in the given list, a forbidden one is searched.
 * @param {*} attachmentsGroups
 * @returns item forbidden due to user's access rights
 */
export function findForbiddenAttachmentItem(attachmentsGroups) {
  const attachmentItems = attachmentsGroups.reduce((list, attachmentsGroup) => {
    if (attachmentsGroup.visible) {
      list.push(attachmentsGroup);
      if (attachmentsGroup.children) {
        list.push(...getVisibleItems(attachmentsGroup.children));
      }
    }
    return list;
  }, []);
  return findForbiddenItem(attachmentItems);
}

/**
 * Determines whether an attachments groups is forbidden or not for the user in terms of
 * the user's access rights. In download and temp pages attachments groups are forbidden
 * when the attachments group is forbidden or the main document (the first child) is.
 * @param {*} attachmentsGroup
 * @returns whether an attachments group is forbidden or not
 */
export function isAttachmentsGroupForbidden(attachmentsGroup) {
  return (
    isItemForbidden(attachmentsGroup) ||
    (attachmentsGroup.children && isItemForbidden(attachmentsGroup.children[0]))
  );
}

/**
 * Finds a forbidden attachments group from a list
 * @param {*} attachmentsGroups
 * @returns forbidden attachments group
 */
export function findForbiddenAttachmentsGroup(attachmentsGroups) {
  return attachmentsGroups.find((downloadGroup) => isAttachmentsGroupForbidden(downloadGroup));
}

/**
 * Finds which item makes an attachmentGroup forbidden
 * @param {*} attachmentsGroup
 * @returns forbidden attachment
 */
export function findForbiddenItemInAttachmentGroup(attachmentsGroup) {
  let forbiddenItem;

  if (isAttachmentsGroupForbidden(attachmentsGroup)) {
    return isItemForbidden(attachmentsGroup) ? attachmentsGroup : attachmentsGroup.children[0];
  }

  return forbiddenItem;
}

/**
 * Opens the default email client and drafts an email to request access for a user
 * @param {*} user
 */
export function sendMail(user) {
  const subject = `PRO : aanvraag toegang documenten door bestuurder ${user.lastName} ${user.firstName}`;
  const body = `Hallo,${'%0D%0D'}${user.username}, ${user.function} in ${
    user.school
  } wil graag toegang tot de documenten van de website PRO.
      ${'%0D'}Geboortedatum : ${user.dateOfBirth}
      ${'%0D'}E-mail: ${user.$$email}`;
  window.location.href = `mailTo:helpdesk.mijn@katholiekonderwijs.vlaanderen?subject=${subject}&body=${body}`;
}

/**
 * Copy the helpdesk email address to the clipboard
 * @param {*} user
 */
export function copyMail() {
  const elem = document.createElement('textarea');
  elem.value = 'helpdesk.mijn@katholiekonderwijs.vlaanderen';
  document.body.appendChild(elem);
  elem.select();
  document.execCommand('copy');
  document.body.removeChild(elem);
}

/**
 * Determine the security level of a user. We SHOULD NEVER GET HERE without a userKey
 * @param {*} user
 * @returns an array of namedSets which can be used to determine security levels
 */
function findInacessibleChild(item) {
  let inacessibleChild;
  if (item.children) {
    item.children.forEach((c) => {
      if (!inacessibleChild) {
        // as long as we haven't found any
        if (!hasPermissions(c)) {
          // found a child that's not accessible
          inacessibleChild = c;
        } else {
          // these are all accessible. go deeper.
          const child = findInacessibleChild(c);
          if (child) {
            inacessibleChild = child;
          }
        }
      }
    });
  }
  return inacessibleChild;
}

/**
 * Determine the security level of a path
 * @param {*} securityLevel
 * @returns the security level of the given path
 */
export function checkPathSecurity(path, theme) {
  if (!theme) return null;

  const userKey = getUserKey(store.getState());
  if (userKey === undefined) return 'afgeschermd1';
  const itemMap = utils.flattenTreeToMap(theme, false);
  let leaf = itemMap.get(path);

  if (!leaf || !leaf.securityLevel) {
    // if you entered pro in a path that points to a shielded node, and you don't have access to it, the leaf will be undefined.
    // in this case, we don't know its parent shielding either.
    if (!hasPermissions(theme)) {
      // if the root is shielded, pick that one.
      leaf = theme;
    } else {
      leaf = findInacessibleChild(theme);
    }
  }
  if (leaf.securityLevel !== SECURITYLEVELS.PUBLIC) {
    if (!hasPermissions(leaf)) {
      return getSecurityStatusFromSecurityLevel(leaf.securityLevel);
    }
  }

  return null;
}

/**
 * Find the href of a secured path of a website
 * @param {*} href
 * @returns a href
 */
export function getHrefOfSecurePath(theme) {
  try {
    const leaves = utils.flattenTree(theme);
    return leaves.find((l) => window.location.pathname === l.pagePath).href;
  } catch (e) {
    return null;
  }
}
